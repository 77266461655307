import { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import throttle from "lodash.throttle";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { fetchMarkets as fetchMarketsAction, fetchMarketsAsync } from "@redux/markets/actions";
import { updateMarkets } from "@redux/markets/slicer";
import { UserMode } from "@type/api/user-mode";
import { isLiteOnly, isProOnly } from "@utils/market";
import useUserMode from "./useUserMode";
const throttleRefetch = throttle((action: Function) => action(), 60000, {
  trailing: false
});
interface Filter {
  search: string;
}
export interface MarketArgs {
  mode?: UserMode;
  filter?: Filter;
  enable?: boolean;
  delisted?: boolean;
}

const useMarkets = (args: MarketArgs = {}) => {
  const {
    markets,
    mode,
    enable,
    delisted
  } = useAppSelector(state => state.markets);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    userMode
  } = useUserMode();
  const onCloseRef = useRef<string>();

  const fetchMarkets = (newMode: UserMode) => {
    const newEnable = args.enable || false;
    const newDelisted = args.delisted || false;

    if (enable !== newEnable || delisted !== newDelisted) {
      dispatch(fetchMarketsAction({
        mode: args?.mode || newMode,
        enable: args.enable,
        delisted: args.delisted
      }));
      return;
    }

    if (args.mode) {
      if (mode !== args.mode) {
        dispatch(fetchMarketsAction({
          mode: args.mode,
          enable: args.enable,
          delisted: args.delisted
        }));
      }

      return;
    }

    if (mode !== newMode) {
      dispatch(fetchMarketsAction({
        mode: newMode,
        enable: args.enable,
        delisted: args.delisted
      }));
    }
  };

  useEffect(() => {
    fetchMarkets(userMode.mode); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, userMode.mode]);
  const isLoading = !markets;
  const data: MarketModel[] | null = !isLoading ? markets?.filter(market => {
    if (!market) return false;
    const search = args?.filter?.search.toLowerCase();

    if (search) {
      const name = market.name.toLowerCase();
      const code = market.code.toLowerCase();
      return name.includes(search) || code.includes(search);
    }

    return true;
  }) : null;
  const handleUpdateMarkets = useCallback((newData: MarketSocketPayload) => {
    if (newData.is_pro && mode === UserMode.Pro) {
      dispatch(updateMarkets(newData));
      return;
    }

    if (newData.is_lite && mode === UserMode.Lightning) {
      dispatch(updateMarkets(newData));
      return;
    }

    const market = markets?.find(item => item.code === newData.c);

    if (market) {
      if (isProOnly(market) || isLiteOnly(market)) {
        dispatch(updateMarkets(newData));
      }
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch, mode]);
  useEffect(() => {
    ;

    (async () => {
      const {
        default: Socket
      } = await import("@lib/socket");
      Socket.subscribe("market", handleUpdateMarkets);
    })();

    return () => {
      ;

      (async () => {
        const {
          default: Socket
        } = await import("@lib/socket");
        Socket.unsubscribe("market");
      })();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUpdateMarkets]);
  useEffect(() => {
    ;

    (async () => {
      const {
        default: Socket
      } = await import("@lib/socket");
      onCloseRef.current = Socket.socket.onClose(() => {
        throttleRefetch(() => {
          dispatch(fetchMarketsAsync({
            mode: userMode.mode,
            enable: args.enable,
            delisted: args.delisted
          }));
        });
      });
    })();

    return () => {
      ;

      (async () => {
        const {
          default: Socket
        } = await import("@lib/socket");
        if (onCloseRef.current) Socket.socket.off([onCloseRef.current]);
      })();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMode.mode]);
  return {
    markets: data,
    fetchMarkets,
    isLoading
  };
};

export const useMarketByCode = (code: string, mode?: UserMode) => {
  const [data, setData] = useState<MarketModel>();
  const {
    markets,
    isLoading
  } = useMarkets({
    mode
  });
  useEffect(() => {
    if (markets) {
      const market = markets.find(item => item.code === code);

      if (market) {
        setData(market);
      }
    }
  }, [markets, code]);
  return {
    market: data,
    isLoading
  };
};
export default useMarkets;